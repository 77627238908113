<template>
  <main>
    <page-hero site="Informacje"/>
    <section class="info-section">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <h4><i class="fa fa-info"></i> Informacje o serwerze</h4>
                    <hr>
                    <table class="table tab">
                        <tr>
                            <td>Nazwa serwera</td>
                            <td>
                                <b-skeleton v-if="loading" width="200px"/>
                                <span v-else>{{ data.serverInfo.name }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>Ilość kanałów</td>
                            <td>
                                <b-skeleton v-if="loading" width="100px"/>
                                <span v-else>{{ data.serverInfo.channels }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>Maksymalna liczba klienów</td>
                            <td>
                                <b-skeleton v-if="loading" width="50px"/>
                                <span v-else>{{ data.serverInfo.maxClients }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>Liczba zarezerwowanych slotów</td>
                            <td>
                                <b-skeleton v-if="loading" width="50px"/>
                                <span v-else>{{ data.serverInfo.reserved}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>Ping serwera</td>
                            <td>
                                <b-skeleton v-if="loading" width="70px"/>
                                <span v-else>{{ data.serverInfo.ping}} ms</span>
                            </td>
                        </tr>
                        <tr>
                            <td>Data utworzenia serwera</td>
                            <td>
                                <b-skeleton v-if="loading" width="100px"/>
                                <span v-else>{{ data.serverInfo.created | moment('DD-MM-YYYY HH:mm') }}</span>
                            </td>
                        </tr>
                    </table>
                    <small v-if="!loading"><i>Stan na: {{ data.time | moment('DD-MM-YYYY HH:mm:ss') }}</i></small>
                </div>
                <div class="col-md-6">
                    <h4><i class="fa fa-chart-line"></i> Wykres osób online</h4>
                    <hr>
                    <div class="text-center">
                        <apexchart
                            v-if="!loading && series[0]"
                            class="apex-charts"
                            height="350"
                            type="area"
                            dir="ltr"
                            :series="series"
                            :options="chartOptions"
                        />
                        <div v-else-if="loading" class="spinner mt-5">
                            <b-spinner label="Loading..."></b-spinner>
                        </div>
                        <h4 v-else class="text-warning p-5"><i class="fa fa-exclamation-triangle"></i> Brak statystyk</h4>
                    </div>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-md-12 preview">
                    <h4><i class="fa fa-eye"></i> Podgląd serwera</h4>
                    <hr>
                    <div v-if="!loading && data.preview">
                        <ul class="teamspeak-channels">
                            <channel
                                v-for="channel in data.preview.data"
                                :key="'main-' + channel.cid"
                                :channel="channel"
                                :clickable="false"
                                isParent
                                real
                            />
                        </ul>
                        <small><i>Stan na: {{ data.preview.time | moment("DD-MM-YYYY HH:mm:ss") }}</i></small>
                    </div>
                    <div v-else-if="loading" class="text-center mt-5">
                        <div class="spinner">
                            <b-spinner label="Loading..."></b-spinner>
                        </div>
                    </div>
                    <div v-else class="text-center">
                        <h4 class="text-warning p-5"><i class="fa fa-exclamation-triangle"></i> Chwilowo podgląd jest niedostępny</h4>
                    </div>
                </div>
            </div>
        </div>
    </section>
  </main>
</template>

<script>
import MainService from "../services/main.service";
import moment from 'moment';

export default {
    name: "Info",
    data() {
        return {
            loading: true,
            data: {},
            series: [],
            chartOptions: {
                chart: {
                    toolbar: {
                        show: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth',
                    width: 3,
                },
                colors: ['#556ee6', '#34c38f'],
                xaxis: {
                    type: 'datetime',
                    categories: [],
                },
                grid: {
                    borderColor: '#f1f1f1',
                },
                tooltip: {
                    x: {
                        format: 'dd-MM-yy HH:mm'
                    },
                }
            }
        }
    },
    mounted() {
        this.loadData();
    },
    methods: {
        moment: moment,
        loadData() {
            this.loading = true;

            MainService.getInfo().then(
                response => {
                    this.data = response.data;
                    this.setStats();
                    this.loading = false;
                },
                error => {
                    console.log(error);
                    this.loading = false;
                }
            )
        },
        setStats() {
            if (!this.data.stats)
                return;

            const series = {
                name: "Osób online",
                data: []
            };

            this.chartOptions.xaxis.categories = [];

            this.data.stats.forEach(stat => {
                series.data.push(stat.online);
                const date = this.moment(stat.date, 'DD-MM-YYYY HH:mm:ss').toISOString();
                this.chartOptions.xaxis.categories.push(date);
            })

            this.series = [series];
        }
    }
}
</script>

<style scoped>

</style>